import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import SearchTheater from 'website/components/push/SearchTheater';
import allocineContainer from 'website/containers/allocineContainer';

export default function searchTheaterPush() {
  const positions = document.getElementsByClassName('js-search-theater-push');
  if (positions.length) {
    const ConnectedSearchTheater = allocineContainer(SearchTheater);
    for (const position of positions) {
      const route = readAttribute(position, 'data-route');
      const placeholder = readAttribute(position, 'data-placeholder');
      const root = createRoot(position);
      root.render(
        <ConnectedSearchTheater route={route} placeholder={placeholder} />
      );
    }
  }
}
