import React, { useState, FormEvent } from 'react';

import { path } from 'common/tools/network/routing';
import trans from 'common/tools/translations/trans';

import { setGeocodeCookieById } from 'website/api/Geocoding';
import LocationAutocomplete from 'website/components/ui/LocationAutocomplete';

type Route = {
  name: string;
  objectParameters: {
    [key: string]: string | undefined;
  };
  defaultParameters?: {
    [key: string]: string | undefined;
  };
};

type TheaterRoute = Route & {
  objectParameters: {
    idTheater?: string;
  };
};

type LocationRoute = Route & {
  objectParameters: {
    localization?: string;
  };
};

type SearchRoute = Route & {
  defaultParameters: {
    movie?: string;
  };
};

type LocationAutocompleteItem = {
  node: {
    [key: string]: string | undefined;
    type: 'theater' | 'city';
  };
};

export type RouteProps = {
  locationRoute: LocationRoute;
  theaterRoute: TheaterRoute;
  searchRoute: SearchRoute;
};

type Props = {
  placeholder?: string;
  route: RouteProps;
  autocompleteOnly?: boolean;
};

const SearchFormLocalization = ({
  placeholder,
  route,
  autocompleteOnly
}: Props) => {
  const [inputValue, setInputValue] = useState('');

  const getLocationSearchUrl = (searchValue: string) => {
    const { searchRoute } = route;

    const routeParameters = {
      ...searchRoute.defaultParameters,
      q: searchValue
    };

    return path(searchRoute.name, routeParameters);
  };

  // case of a localization (geocode)
  const handleLocationChange = async (item: LocationAutocompleteItem) => {
    let destination: LocationRoute | TheaterRoute = route.locationRoute;

    // case of a theater (redirection to theaterpage)
    if (item.node.type === 'theater') {
      destination = route.theaterRoute;
    }

    const customParameters:
      | LocationRoute['objectParameters']
      | TheaterRoute['objectParameters'] = {};

    Object.keys(destination.objectParameters).forEach(key => {
      const value = destination.objectParameters[key];
      if (value) {
        customParameters[key] = item.node[value];
      }
    });

    const routeParameters = {
      ...destination.defaultParameters,
      ...customParameters
    };

    //we only store the geocode cookie when the result is a localization (not a theater)
    if (
      item.node.type === 'city' &&
      'localization' in routeParameters &&
      routeParameters.localization
    ) {
      await setGeocodeCookieById(routeParameters.localization, 'localization');
    }

    window.location = path(destination.name, routeParameters) as any;
  };

  const handleLocationSearch = (term: string) => {
    if (!term) {
      return false;
    }

    window.location = getLocationSearchUrl(term) as any;
  };

  const handleInputValueChange = (value: string) => {
    setInputValue(value);
  };

  const handleSubmit = (event: FormEvent) => {
    if (!inputValue) {
      event.preventDefault();
      return false;
    }
  };

  const autocompleteInputProps = {
    className: 'form-text',
    type: 'text',
    placeholder: placeholder,
    name: 'q'
  };

  const autocomplete = (
    <LocationAutocomplete
      inputProps={autocompleteInputProps}
      getSearchUrl={getLocationSearchUrl}
      onChange={handleLocationChange}
      onSearch={handleLocationSearch}
      onInputValueChange={handleInputValueChange}
    />
  );

  if (autocompleteOnly) {
    return autocomplete;
  }

  return (
    <form
      className="form-holder form form-localization"
      method="get"
      action={path(route.searchRoute.name, {
        ...(route.searchRoute.defaultParameters
          ? route.searchRoute.defaultParameters
          : {})
      })}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <fieldset className="form-field">
        {autocomplete}
        <button
          className="form-submit button button-md icon icon-search"
          aria-label={trans('autocomplete.submit')}
          type="submit"
          title={trans('autocomplete.submit')}
        />
      </fieldset>
    </form>
  );
};

export default SearchFormLocalization;
