import PropTypes from 'prop-types';
import React from 'react';

import trans from 'common/tools/translations/trans';

import SearchLocalizationForm from 'website/components/ui/SearchLocalizationForm';
import FavoriteTheatersSelector from 'website/components/user/FavoriteTheatersSelector';

const SearchTheater = props => {
  return (
    <div>
      <SearchLocalizationForm {...props} autocompleteOnly={false} />
      <FavoriteTheatersSelector
        title={trans('homepage_movie.favorite_theaters')}
      />
    </div>
  );
};

SearchTheater.propTypes = {
  route: PropTypes.shape({
    locationSearch: PropTypes.shape({
      route: PropTypes.string
    })
  }).isRequired
};

export default SearchTheater;
