import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import DropdownLinks from 'common/components/generic/DropdownLinks';
import { path } from 'common/tools/network/routing';
import trans from 'common/tools/translations/trans';

import { getUserTheaters } from 'website/actions/TheaterActions';
import onlyIfHasSocial from 'website/components/HoC/onlyIfHasSocial';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { REQUEST_LOADING } from 'website/constants/RequestsStatuses';

const FavoriteTheatersSelector = ({ title, user, dispatch }) => {
  useEffect(() => {
    if (user.loggedIn) {
      dispatch(getUserTheaters());
    }
  }, [user.loggedIn]);

  const formatTheaters = () => {
    const formattedTheaters = [];

    user.theaters.forEach((value, key) => {
      formattedTheaters.push({
        text: value.data.label,
        href: path('theater_showtimes', { idTheater: key })
      });
    });

    if (user.theatersCount > user.theaters.size) {
      formattedTheaters.push({
        text: trans('homepage_movie.all_favorite_theaters_title'),
        href: path('userspace_my_theaters')
      });
    }

    return formattedTheaters;
  };

  const renderLoggedOut = () => {
    return (
      <AuthenticatedLink className="search-theater-link blue-link">
        {trans('homepage_movie.theaters-logged-out')}
      </AuthenticatedLink>
    );
  };

  const renderNoFavorites = () => {
    return (
      <a
        className="search-theater-link blue-link"
        href={path('userspace_my_theaters')}
      >
        {trans('homepage_movie.no_favorite_theaters')}
      </a>
    );
  };

  let content = null;

  if (!user.loggedIn || user.theaters === null) {
    content = renderLoggedOut();
  } else if (user.favTheatersStatus === REQUEST_LOADING) {
    // display loggedout status while fetching
    content = renderLoggedOut();
  } else if (user.theaters.size === 0) {
    content = renderNoFavorites();
  } else {
    content = <DropdownLinks title={title} items={formatTheaters()} />;
  }

  return <div className="search-theater-favorite">{content}</div>;
};

FavoriteTheatersSelector.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.shape({
    favTheatersStatus: PropTypes.any,
    loggedIn: PropTypes.bool.isRequired,
    theaters: PropTypes.any,
    theatersCount: PropTypes.any
  }).isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(
  onlyIfHasSocial(FavoriteTheatersSelector)
);
