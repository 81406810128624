import classNames from 'classnames';
import Cookies from 'cookies-js';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Location,
  Maybe,
  Showtime,
  ShowtimeVersion
} from 'types/graphql-api.generated';

import Button from 'common/components/Button';
import ShowtimesHour from 'common/components/Showtimes/ShowtimesHour';
import { ROUTE } from 'common/configuration/constants';
import { GEOLOCALISATION_GEOCODE } from 'common/constants/CookieNames';
import {
  ReservationTrackingShowtime,
  ReservationTrackingTheater
} from 'common/constants/trackingEventsNames';
import formatDate, { DATE_FORMATS } from 'common/tools/date/format';
import { dateProcessingFromAString } from 'common/tools/date/showtime';
import request from 'common/tools/network/request';
import { path } from 'common/tools/network/routing';
import { desobfuscateUrl } from 'common/tools/seo/desobfuscation';
import getTicketingUrl from 'common/tools/showtime/getTicketingUrl';
import slugify from 'common/tools/string/slugify';
import trans from 'common/tools/translations/trans';
import { hit, print } from 'common/tracking/googleanalytics';
import { hit as hit_jan, print as print_jan } from 'common/tracking/jan';
import { EventData } from 'common/tracking/types';

import allocineContainer from 'website/containers/allocineContainer';
import {
  PartialTheater,
  ShowtimesByVersions
} from 'website/containers/showtimes/utils/types';
import {
  getReservationTracking,
  getReservationTrackingGA4
} from 'website/tracking/listener/reservationTracking';

import styles from './styles.module.scss';

export type TheaterData = {
  showtimes?: ShowtimesByVersions<ShowtimeVersion>;
  theater?: PartialTheater;
};

type ShowtimesRequest = ReservationTrackingShowtime &
  Pick<Showtime, '__typename' | 'internalId' | 'tags'>;

type TheaterRequest = ReservationTrackingTheater & {
  location?: Maybe<Pick<Location, 'address' | 'city' | 'region' | 'zip'>>;
};

type ShowtimesFilteredRequest = {
  error: boolean;
  message: string;
  results?: { theater: TheaterRequest; showtimes: ShowtimesRequest[] };
};

type Props = {
  ctaTextAfterRelease?: string;
  ctaTextBeforeRelease?: string;
  displayShowtimes?: boolean;
  entityId: number;
  entityRelease?: string;
  entityTitle?: string;
  entityType?: string;
  operationLink?: string;
  textAfterRelease?: string;
  textBeforeRelease?: string;
  theaterId?: string;
  tracking?: Record<string, any>;
};

/* GLOBAL VARS */
const SHOWTIME_PLACE_API_ROUTE =
  'internal_api_showtimes_ticket_anchor_place_filtered';
const SHOWTIME_THEATER_API_ROUTE =
  'internal_api_showtimes_ticket_anchor_theater_filtered';

const operationText = trans('showtime.anchor.operation.text');
const operationLinkText = trans('showtime.anchor.operation.link');
const notGeolocalized = 'not_geolocalized';

const getLabels = (
  entityReleaseDate?: string,
  textAfterRelease?: string,
  textBeforeRelease?: string,
  ctaTextAfterRelease?: string,
  ctaTextBeforeRelease?: string
) => {
  let operationLabel: string;
  let operationLinkLabel: string;

  const releaseTime = entityReleaseDate
    ? new Date(entityReleaseDate).getTime()
    : 0;

  if (releaseTime > 0 && Date.now() >= releaseTime) {
    operationLabel = textAfterRelease ?? operationText;
    operationLinkLabel = ctaTextAfterRelease ?? operationLinkText;
  } else {
    operationLabel = textBeforeRelease ?? operationText;
    operationLinkLabel = ctaTextBeforeRelease ?? operationLinkText;
  }

  return [operationLabel, operationLinkLabel];
};

const AnchorPlus = ({
  ctaTextAfterRelease,
  ctaTextBeforeRelease,
  displayShowtimes,
  entityId,
  entityRelease,
  entityTitle,
  entityType,
  operationLink,
  textAfterRelease,
  textBeforeRelease,
  theaterId,
  tracking
}: Props) => {
  const eventData = useRef<EventData>({
    eventCategory: 'ticket_office',
    dimension5: 'undefined',
    dimension6: 'undefined',
    dimension10: ROUTE,
    dimension29: 'undefined',
    dimension30: 'undefined',
    dimension65: 'undefined',
    dimension66: 'undefined',
    dimension69: 'undefined',
    dimension82: `${entityId}-${slugify(entityTitle)}`,
    dimension84: entityType?.toLowerCase()
  });

  const trackingData = useRef<Record<string, any>>({
    ...(tracking ?? {}),
    ...getReservationTrackingGA4(undefined, undefined, {
      internalId: entityId,
      title: entityTitle
    }),
    site_route: ROUTE
  });

  const [operationLabel, operationLinkLabel] = getLabels(
    entityRelease,
    textAfterRelease,
    textBeforeRelease,
    ctaTextAfterRelease,
    ctaTextBeforeRelease
  );

  const [theaterData, setTheaterData] = useState<TheaterRequest | undefined>(
    undefined
  );
  const [showtimesData, setShowtimesData] = useState<ShowtimesRequest[]>([]);
  const [eventLabel, setEventLabel] = useState<string | undefined>();
  const [GAPrinted, setGAPrinted] = useState(false);

  const printGA = useCallback(
    (theaterGALabel?: string) => {
      setEventLabel(theaterGALabel);

      if (!GAPrinted) {
        print({
          ...eventData.current,
          eventLabel: theaterGALabel ?? notGeolocalized
        });

        print_jan('eticketing', trackingData.current);

        setGAPrinted(true);
      }
    },
    [GAPrinted, trackingData]
  );

  const updateTheater = useCallback(
    (showtimes: ShowtimesRequest[], theater: TheaterRequest) => {
      eventData.current = {
        ...eventData.current,
        ...getReservationTracking(undefined, theater, undefined)
      };
      trackingData.current = {
        ...trackingData.current,
        ...getReservationTrackingGA4(undefined, theater, {
          internalId: entityId,
          title: entityTitle
        })
      };

      printGA(
        theater ? `${theater.internalId}-${slugify(theater.name)}` : undefined
      );
      setTheaterData(theater);
      setShowtimesData(showtimes);
    },
    [entityId, entityTitle, printGA]
  );

  const requestShowtimes = useCallback(
    async (url: string) => {
      try {
        if (!url.length) {
          throw new Error('no provided url');
        }

        const data = await request<ShowtimesFilteredRequest>(url);

        const results = data === null || data.error ? null : data.results;

        if (!results) {
          throw new Error('no results');
        }

        const { theater, showtimes } = results;

        updateTheater(showtimes, theater);
      } catch (e) {
        printGA();
      }
    },
    [printGA, updateTheater]
  );

  const getTheaterById = useCallback(
    (idTheater: string) => {
      const url = path(SHOWTIME_THEATER_API_ROUTE, {
        idTheater,
        movie: entityId
      });
      requestShowtimes(url);
    },
    [entityId, requestShowtimes]
  );

  const getTheaterByGeocode = useCallback(
    (geocode: string) => {
      const url = path(SHOWTIME_PLACE_API_ROUTE, {
        localization: geocode,
        movie: entityId
      });
      requestShowtimes(url);
    },
    [entityId, requestShowtimes]
  );

  useEffect(() => {
    if (!displayShowtimes) {
      printGA();
      return;
    }

    if (theaterId) {
      getTheaterById(theaterId);
    } else {
      const geocode = Cookies.get(GEOLOCALISATION_GEOCODE);

      if (geocode) {
        getTheaterByGeocode(geocode);
      } else {
        printGA();
      }
    }
  }, [
    displayShowtimes,
    entityId,
    getTheaterByGeocode,
    getTheaterById,
    printGA,
    theaterId
  ]);

  const desobfuscateOperationLink = desobfuscateUrl(operationLink);

  const handleClickOperationLink = (fallbackLabel: string) => {
    hit({ ...eventData.current, eventLabel: eventLabel ?? fallbackLabel });
    hit_jan(
      `eticketing_click_${fallbackLabel === 'click_left' ? 'left' : 'button'}`,
      trackingData.current
    );
  };

  const renderShowtime = () =>
    showtimesData.map((showtime, index) => {
      // use index for a key is a bad practice, but showtime hour is too messy and always trigger some key error
      const key = `${showtime.internalId}_${index}_${showtime.startsAt}`;
      const ticketingUrl = getTicketingUrl(showtime.data?.ticketing);
      const className = classNames({ bookable: ticketingUrl });
      const startsAt = dateProcessingFromAString(showtime.startsAt);

      return (
        <ShowtimesHour
          ticketingUrl={ticketingUrl}
          className={className}
          hour={startsAt.hour}
          experiences={showtime.tags}
          key={key}
          tracking={{
            movie: { internalId: entityId, title: entityTitle },
            theater: theaterData,
            showtime,
            eventData: {
              ...eventData.current,
              eventAction: 'clic',
              eventCategory: 'ticket_office'
            },
            ga4event: 'eticketing_click_button',
            ga4data: trackingData.current
          }}
        />
      );
    });

  const renderOff = () => (
    <>
      <div className={styles.theaterSoon}>{operationLabel}</div>
      <Button
        href={desobfuscateOperationLink}
        theme="inverse"
        type="full"
        onClick={() => handleClickOperationLink(operationLabel)}
      >
        {operationLinkLabel}
      </Button>
    </>
  );
  const renderTheater = () => (
    <>
      <div className={styles.theaterName}>{theaterData?.name}</div>
      <div className={styles.theaterAddress}>
        {theaterData?.location?.address +
          ' ' +
          theaterData?.location?.zip +
          ' ' +
          theaterData?.location?.city}
      </div>
      <div className={styles.today}>
        {formatDate(new Date(showtimesData[0].startsAt), {
          timeZone: 'UTC',
          ...DATE_FORMATS.FULL
        })}
      </div>
      <div className={styles.theaterShowtime}>{renderShowtime()}</div>
    </>
  );

  return (
    <>
      <a
        className={styles.operationLink}
        href={desobfuscateOperationLink}
        onClick={() => handleClickOperationLink('click_left')}
      />

      <div className={styles.interactionArea}>
        {displayShowtimes && theaterData && showtimesData.length
          ? renderTheater()
          : renderOff()}
      </div>
    </>
  );
};

const ConnectedAnchorPlus = allocineContainer(AnchorPlus);

export default ConnectedAnchorPlus;
