import Cookies from 'cookies-js';
import { print } from 'graphql';
import cloneDeep from 'lodash/cloneDeep';

import {
  GRAPH_COUNTRY_NAME,
  GRAPH_HOST,
  IMAGE_EMPTY_AVATAR
} from 'common/configuration/constants';
import {
  DELETE_WANT_TO_SEE_EVENT,
  FOLLOW_COLLECTION_EVENT,
  TrackingEventNames,
  UNFOLLOW_COLLECTION_EVENT
} from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';
import request from 'common/tools/network/request';
import { getImageUrl } from 'common/tools/network/url';
import get from 'common/tools/objects/get';

import { simplifyGraphData } from './GraphApi.helper';

export const requestGraph = ({ query, variables }) => {
  // If query is a DocumentNode, convert it to a string
  const queryString = typeof query === 'string' ? query : print(query);
  return request(`${GRAPH_HOST}/v1/public`, {
    method: 'POST',
    payload: {
      query: queryString,
      variables
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('GraphToken')}`
    }
  }).then(response => {
    if (response.errors && response.errors.length) {
      throw new Error(response.errors[0].message);
    }
    return response;
  });
};

/**
 * Will recursively run the given query with the given variables until pagination end is reached.
 *
 * @param {DocumentNode} query A GraphQL query that must contain an $after variable used as a cursor
 * @param {Object|undefined} variables A list of variables for the query
 * @param {Array<String>} paginationPath An array pointing to the object holding "pageInfo" in the response
 * @return {Promise} a repsonse object where the different pages edges are merged into one
 */
export const exhaustPagination = async (query, variables, paginationPath) => {
  const response = await requestGraph({ query, variables });
  const pageInfo = get(response, ['data', ...paginationPath, 'pageInfo']);
  if (pageInfo.hasNextPage) {
    const nextPageResponse = await exhaustPagination(
      query,
      {
        ...(variables || {}),
        after: pageInfo.endCursor
      },
      paginationPath
    );
    const finalResponse = cloneDeep(nextPageResponse);
    const merged = get(finalResponse, ['data', ...paginationPath]);
    const previousEdges = get(response, ['data', ...paginationPath, 'edges']);
    merged.edges = [...previousEdges, ...merged.edges];
    return finalResponse;
  }
  return response;
};

export const getUserById = userId => {
  const QUERY = /* GraphQL */ `
    query GetUserById($userId: String!) {
      user(id: $userId) {
        nickname
        mainAvatar
      }
    }
  `;

  return requestGraph({
    query: QUERY,
    variables: {
      userId
    }
  });
};

export const isUserFollowed = userId => {
  const QUERY = /* GraphQL */ `
    query amIFollowingThisGuyQuery($userId: String!) {
      me {
        user {
          social {
            userConnections {
              followees(id: [$userId]) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return requestGraph({
    query: QUERY,
    variables: {
      userId
    }
  });
};

export const getCurrentUser = () => {
  const QUERY = /* GraphQL */ `
    query getUser {
      me {
        user {
          legacyId
          nickname
          mainAvatar
          email
          profile {
            isEmailValidated
            isCguValidated
          }
        }
      }
    }
  `;
  return requestGraph({ query: QUERY }).then(response => {
    const userData = response?.data?.me?.user;
    if (!userData) return Promise.reject(new Error('no user data'));
    return {
      email: userData.email,
      isEmailValidated: userData?.profile?.isEmailValidated,
      isCguValidated: userData?.profile?.isCguValidated,
      id: userData.legacyId,
      label: userData.nickname,
      picture: userData.mainAvatar || getImageUrl({ src: IMAGE_EMPTY_AVATAR })
    };
  });
};

export const followUser = async (userId, context) => {
  const MUTATION = `
    mutation followUser($userId: String!) {
      addUserFollowUser(input: { id_publisher: $userId }) {
        id
      }
    }
  `;

  const response = await requestGraph({
    query: MUTATION,
    variables: {
      userId
    }
  });

  eventEmitter.emit(TrackingEventNames.FOLLOW_USER_EVENT, {
    context,
    response
  });

  return response;
};

export const unfollowUser = async (userId, context) => {
  const MUTATION = `
    mutation unfollowUser($userId: String!) {
      deleteUserFollowUser(input: { id_publisher: $userId }) {
        id
      }
    }
  `;

  const response = await requestGraph({
    query: MUTATION,
    variables: {
      userId
    }
  });

  eventEmitter.emit(TrackingEventNames.UNFOLLOW_USER_EVENT, {
    context,
    response
  });

  return response;
};

export const followCollection = async collectionId => {
  const MUTATION = `
    mutation followCollection($collectionId: String!) {
      followUserCollection(input: { id_collection: $collectionId }) {
        id
      }
    }
  `;

  const response = await requestGraph({
    query: MUTATION,
    variables: {
      collectionId
    }
  });

  eventEmitter.emit(FOLLOW_COLLECTION_EVENT);

  return response;
};

export const unfollowCollection = async collectionId => {
  const MUTATION = `
    mutation unfollowCollection($collectionId: String!) {
      unfollowUserCollection(input: { id_collection: $collectionId }) {
        id
      }
    }
  `;

  const response = await requestGraph({
    query: MUTATION,
    variables: {
      collectionId
    }
  });

  eventEmitter.emit(UNFOLLOW_COLLECTION_EVENT);

  return response;
};

export const isCollectionFollowed = collectionId => {
  const QUERY = /* GraphQL */ `
    query GET_FOLLOWED_COLLECTIONS($collectionId: ID!) {
      me {
        user {
          id
          social {
            followedCollections(ids: [$collectionId]) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  return requestGraph({
    query: QUERY,
    variables: {
      collectionId
    }
  });
};

export const getUserAvatar = userId => {
  const QUERY = /* GraphQL */ `
    query GetUserAvatar($userId: String!) {
      user(id: $userId) {
        mainAvatar
      }
    }
  `;

  return requestGraph({
    query: QUERY,
    variables: {
      userId
    }
  });
};

const ENTITY_FRAGMENT = `
fragment entityFragment on Node {
	id
  __typename
  ... on Movie {
    title
    poster {
      path
    }
    flags {
      isComingSoon
    }
    releases(country: ${GRAPH_COUNTRY_NAME}, type: RELEASED) {
      releaseDate {
        date
      }
    }
  }
  ... on Series {
    title
    originalBroadcast {
      firstAiredDate {
        date
      }
    }
  }
  ... on Season {
    number
    status
  }
  ... on Program {
    title
  }
  ... on Episode {
    title
  }
}
`;

const USER_AFFINITY_FRAGMENT = `
fragment userAffinityFragment on Node {
  id
  __typename
  ... on Movie {
    userAffinity {
      reason
      affinityScore
    }
  }
  ... on Series {
    userAffinity {
      reason
      affinityScore
    }
  }
}
`;

const SOCIAL_ACTION_FRAGMENT = `
fragment SocialActionFragment on SocialActionInterface {
  __typename
  id
  updatedAt
  relatedEntity {
    __typename
    id
  }
  ... on Opinion {
    content {
      rating(base: 5)
      review
      status
    }
  }
}
`;
const USER_ENTITY_LEAF_FRAGMENT = `
fragment UserEntityLeafFragment on UserEntityLeafInterface {
  id
  entity {
    ...entityFragment
  }
  opinion {
    ...SocialActionFragment
  }
  wantToSee {
    ...SocialActionFragment
  }
  helpful {
    ...SocialActionFragment
  }
  unhelpful {
    ...SocialActionFragment
  }
  seenIt {
    ...SocialActionFragment
  }
}
${SOCIAL_ACTION_FRAGMENT}${ENTITY_FRAGMENT}`;

export const getUserSocialActionsForEntities = (
  entityIds,
  actionTypes = []
) => {
  const decodedEntityIds = entityIds.reduce((acc, entityId) => {
    const decode = window.atob(entityId).split(':');

    if (!acc[decode[0]]) {
      acc[decode[0]] = [];
    }

    acc[decode[0]].push(window.parseInt(decode[1], 10));

    return acc;
  }, {});

  const QUERY = /* GraphQL */ `
    query GetUserSocialActionsForEntities(
      $entityIds: [String]!
      $action: [UserSocialActionType]
      $movieIds: [Int]
      $moviesFirst: Int
      $seriesIds: [Int]
      $seriesFirst: Int
    ) {
      me {
        user {
          social {
            entities(entity: $entityIds, action: $action) {
              edges {
                node {
                  ...UserEntityLeafFragment
                }
              }
            }
          }
        }
      }
      movieList(include: $movieIds, first: $moviesFirst) {
        edges {
          node {
            ...userAffinityFragment
          }
        }
      }
      seriesList(include: $seriesIds, first: $seriesFirst) {
        edges {
          node {
            ...userAffinityFragment
          }
        }
      }
    }
    ${USER_ENTITY_LEAF_FRAGMENT}
    ${USER_AFFINITY_FRAGMENT}
  `;

  if (!entityIds || !entityIds.length) {
    // Graph, if not provided with a list of IDs, will return all social actions
    return Promise.resolve([]);
  }

  const movieIds = decodedEntityIds.Movie ?? [];
  const seriesIds = decodedEntityIds.Series ?? [];

  return requestGraph({
    query: QUERY,
    variables: {
      entityIds,
      movieIds,
      moviesFirst: movieIds.length,
      seriesIds,
      seriesFirst: seriesIds.length,
      action: actionTypes
    }
  }).then(response => {
    const result = response?.data?.me?.user?.social?.entities?.edges?.map?.(
      ({ node }) => node
    );

    const mergeEntities = ({ node }) => {
      const idx = result.findIndex(value => value.entity.id === node.id);
      if (idx >= 0) {
        result[idx].entity = { ...result[idx].entity, ...node };
      } else {
        result.push({
          entity: node,
          opinion: null,
          wantToSee: null,
          helpful: null,
          unhelpful: null,
          seenIt: null
        });
      }
    };

    response?.data?.movieList?.edges.forEach(mergeEntities);
    response?.data?.seriesList?.edges.forEach(mergeEntities);

    return result;
  });
};

/**
 * _
 * @param {Array|String} entityIds one or more graph ids
 * @return {Promise} resolved with one UserEntityLeaf (or array of UserEntityLeaf)
 */
export const getUserOpinions = entityIds => {
  const _entityIds = Array.isArray(entityIds) ? entityIds : [entityIds];
  const QUERY = /* GraphQL */ `
    query GetUserOpinion($entityIds: [String]!) {
      me {
        user {
          social {
            entities(entity: $entityIds, action: [OPINION]) {
              edges {
                node {
                  ...UserEntityLeafFragment
                }
              }
            }
          }
        }
      }
    }
    ${USER_ENTITY_LEAF_FRAGMENT}
  `;

  return requestGraph({
    query: QUERY,
    variables: {
      entityIds: _entityIds
    }
  }).then(response => {
    const leafs =
      get(response, ['data', 'me', 'user', 'social', 'entities', 'edges']).map(
        ({ node }) => node
      ) || [];
    if (_entityIds.length === 1) return leafs[0];
    return leafs;
  });
};

export const addUserOpinion = (entityId, rating, review, context) => {
  const QUERY = `
mutation addUserOpinion($entityId: String!, $rating: Float, $review: String) {
  addUserOpinion(input: {id_entity: $entityId, rating: $rating, review: $review}) {
    userEntityLeaf {
      ...UserEntityLeafFragment
    }
  }
}${USER_ENTITY_LEAF_FRAGMENT}`;

  return requestGraph({
    query: QUERY,
    variables: {
      entityId,
      rating,
      review
    }
  })
    .then(response =>
      get(response, ['data', 'addUserOpinion', 'userEntityLeaf'])
    )
    .then(response => {
      eventEmitter.emit(TrackingEventNames.ADD_OPINION_EVENT, {
        context,
        response
      });
      return response;
    });
};

export const updateUserOpinion = (entityId, rating, review, context) => {
  const QUERY = `
mutation updateUserOpinion($entityId: String!, $rating: Float, $review: String) {
  updateUserOpinion(input: {id_entity: $entityId, rating: $rating, review: $review}) {
    userEntityLeaf {
      ... UserEntityLeafFragment
    }
  }
}${USER_ENTITY_LEAF_FRAGMENT}`;

  // dispatch for tracking
  return requestGraph({
    query: QUERY,
    variables: {
      entityId,
      rating,
      review
    }
  })
    .then(response =>
      get(response, ['data', 'updateUserOpinion', 'userEntityLeaf'])
    )
    .then(response => {
      eventEmitter.emit(TrackingEventNames.UPDATE_OPINION_EVENT, {
        context,
        response
      });
      return response;
    });
};

export const deleteUserOpinion = (entityId, context) => {
  const QUERY = `
mutation deleteUserOpinion($entityId: String!) {
  deleteUserOpinion(input: { id_entity: $entityId }) {
    userEntityLeaf {
      id
    }
  }
}
`;
  // dispatch for tracking
  return requestGraph({
    query: QUERY,
    variables: {
      entityId
    }
  }).then(response => {
    eventEmitter.emit(TrackingEventNames.DELETE_OPINION_EVENT, {
      context,
      response
    });
    return response;
  });
};

export const addWantToSee = (entityId, context) => {
  const QUERY = `
mutation addWantToSee($entityId:String!) {
  addUserSocialAction(input:{id_entity:[$entityId],action:WANTTOSEE}) {
    userEntityLeaf {
      ... UserEntityLeafFragment
    }
  }
}
${USER_ENTITY_LEAF_FRAGMENT}`;
  // dispatch for tracking
  return requestGraph({
    query: QUERY,
    variables: {
      entityId
    }
  })
    .then(response =>
      get(response, ['data', 'addUserSocialAction', '0', 'userEntityLeaf'])
    )
    .then(response => {
      eventEmitter.emit(TrackingEventNames.ADD_WANT_TO_SEE_EVENT, {
        context,
        response
      });
      return response;
    });
};

export const deleteWantToSee = (entityId, context) => {
  const QUERY = `
mutation deleteWantToSee($entityId: String!) {
  deleteUserSocialAction(input: {id_entity: [$entityId], action: WANTTOSEE}) {
    userEntityLeaf {
      id
    }
  }
}
`;
  // dispatch for tracking
  return requestGraph({
    query: QUERY,
    variables: {
      entityId
    }
  }).then(response => {
    eventEmitter.emit(DELETE_WANT_TO_SEE_EVENT, {
      context,
      response
    });
    return response;
  });
};

export const similarUnratedMovies = () => {
  const QUERY = /* GraphQL */ `
    query GetMovieRatingSuggestions {
      me {
        user {
          social {
            suggestedEntities {
              movies(
                first: 20
                statusIsNot: [RATING]
                releasedInFuture: false
              ) {
                edges {
                  node {
                    movie {
                      ...entityFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${ENTITY_FRAGMENT}
  `;
  return requestGraph({ query: QUERY }).then(response =>
    get(response, [
      'data',
      'me',
      'user',
      'social',
      'suggestedEntities',
      'movies',
      'edges'
    ]).map(({ node }) => node.movie)
  );
};

export const getHelpfulUnhelpfulForReviews = reviewIds => {
  const QUERY = /* GraphQL */ `
    query GetHelpfulUnhelpfulForReviews($reviewIds: [String!]!) {
      me {
        user {
          social {
            actions(action: [UNHELPFUL, HELPFUL], entity: $reviewIds) {
              edges {
                node {
                  action {
                    ...SocialActionFragment
                  }
                }
              }
            }
          }
        }
      }
    }
    ${SOCIAL_ACTION_FRAGMENT}
  `;

  return requestGraph({
    query: QUERY,
    variables: {
      reviewIds: reviewIds
    }
  }).then(response =>
    get(response, ['data', 'me', 'user', 'social', 'actions', 'edges']).map(
      ({ node }) => node.action
    )
  );
};

export const createOpinionOnReview = (reviewId, type) => {
  const legacyId = window.atob(reviewId).split(':')[1];
  const QUERY = `
  mutation AddUnhelpfulUnhelpful($action: UserSocialClickType!, $opinionId: [String!]!) {
    addUserSocialAction(input: {action: $action, id_entity: $opinionId}) {
      userEntityLeaf {
        ... UserEntityLeafFragment
      }
    }
  }${USER_ENTITY_LEAF_FRAGMENT}`;

  let mappedType;
  if (type === 'helpful') mappedType = 'HELPFUL';
  else mappedType = 'UNHELPFUL';

  return requestGraph({
    query: QUERY,
    variables: {
      action: mappedType,
      opinionId: window.btoa(`Opinion:${legacyId}`)
    }
  }).then(response =>
    get(response, ['data', 'addUserSocialAction', '0', 'userEntityLeaf'])
  );
};

export const deleteOpinionOnReview = (reviewId, type) => {
  const legacyId = window.atob(reviewId).split(':')[1];

  const QUERY = `
mutation RemoveHelpfulUnhelpful($action: UserSocialClickType!, $opinionId: [String!]!) {
  deleteUserSocialAction(input:{action:$action, id_entity: $opinionId}) {
    userEntityLeaf {
      id
    }
  }
}`;

  let mappedType;
  if (type === 'helpful') mappedType = 'HELPFUL';
  else mappedType = 'UNHELPFUL';

  return requestGraph({
    query: QUERY,
    variables: {
      opinionId: window.btoa(`Opinion:${legacyId}`),
      action: mappedType
    }
  });
};

export const retrieveFolloweesAverage = entityId => {
  const QUERY = /* GraphQL */ `
    query followeesAverage($entityId: String!, $after: String) {
      me {
        user {
          social {
            followeesAverageRating(entity: $entityId, base: 5)
            followeesActions(
              action: OPINION
              after: $after
              entity: [$entityId]
            ) {
              edges {
                node {
                  user {
                    id
                    mainAvatar
                    nickname
                  }
                }
              }
            }
            opinionCount: followeesActions(
              action: OPINION
              entity: [$entityId]
            ) {
              totalCount
            }
            wantToSeeCount: followeesActions(
              action: WANTTOSEE
              entity: [$entityId]
            ) {
              totalCount
            }
          }
        }
      }
    }
  `;
  return requestGraph({
    query: QUERY,
    variables: { entityId: entityId }
  }).then(simplifyGraphData);
};

export const retrieveFolloweesOpinions = entityId => {
  const QUERY = /* GraphQL */ `
    query GetFolloweesOpinionOnEntity($entityId: String!, $after: String) {
      me {
        user {
          social {
            followeesActions(
              after: $after
              action: [OPINION, WANTTOSEE]
              entity: [$entityId]
            ) {
              pageInfo {
                hasNextPage
                endCursor
              }
              edges {
                node {
                  user {
                    legacyId
                    nickname
                    mainAvatar
                  }
                  action {
                    __typename
                    ... on Opinion {
                      id
                      updatedAt
                      content {
                        rating(base: 5)
                        status
                      }
                      relatedEntity {
                        id
                      }
                    }
                    ... on WantToSee {
                      id
                      updatedAt
                      relatedEntity {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  return exhaustPagination(QUERY, { entityId }, [
    'me',
    'user',
    'social',
    'followeesActions'
  ]).then(simplifyGraphData);
};

export const markAsSeen = (entityId, context) => {
  const QUERY = `
mutation AddSeenIt($entityId: String!) {
  addUserSocialAction(input: {action: SEENIT, id_entity: [$entityId]}) {
    userEntityLeaf {
      ... UserEntityLeafFragment
    }
  }
}${USER_ENTITY_LEAF_FRAGMENT}`;

  return requestGraph({
    query: QUERY,
    variables: {
      entityId
    }
  })
    .then(response =>
      get(response, ['data', 'addUserSocialAction', '0', 'userEntityLeaf'])
    )
    .then(response => {
      eventEmitter.emit(TrackingEventNames.ADD_SEEN_IT_EVENT, {
        context,
        response
      });
      return response;
    });
};

export const unmarkAsSeen = (entityId, context) => {
  const QUERY = `
mutation RemoveSeenIt($entityId: String!) {
  deleteUserSocialAction(input: {action: SEENIT, id_entity: [$entityId]}) {
    userEntityLeaf {
      ... UserEntityLeafFragment
    }
  }
}${USER_ENTITY_LEAF_FRAGMENT}`;

  return requestGraph({
    query: QUERY,
    variables: {
      entityId
    }
  })
    .then(response =>
      get(response, ['data', 'addUserSocialAction', '0', 'userEntityLeaf'])
    )
    .then(response => {
      eventEmitter.emit(TrackingEventNames.REMOVE_SEEN_IT_EVENT, {
        context,
        response
      });
      return response;
    });
};

export const retrieveUserFollowees = candidatesIds => {
  const QUERY = /* GraphQL */ `
    query RetrieveUserFollowees($candidatesIds: [String]!) {
      me {
        user {
          social {
            userConnections {
              followees(legacyId: $candidatesIds) {
                edges {
                  node {
                    legacyId
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return requestGraph({
    query: QUERY,
    variables: {
      candidatesIds: candidatesIds
    }
  }).then(response =>
    get(response, [
      'data',
      'me',
      'user',
      'social',
      'userConnections',
      'followees',
      'edges'
    ]).map(({ node }) => node)
  );
};

export const createFollowee = (userId, context) => {
  const QUERY = `
mutation followUser($userId: String!) {
  addUserFollowUser(input: {id_publisher: $userId, provider: LEGACY}) {
    relatedEntity {
      __typename
      ... on User {
        legacyId
      }
    }
  }
}`;
  return requestGraph({
    query: QUERY,
    variables: { userId: userId }
  })
    .then(response =>
      get(response, ['data', 'addUserFollowUser', 'relatedEntity'])
    )
    .then(response => {
      eventEmitter.emit(TrackingEventNames.FOLLOW_USER_EVENT, {
        context,
        response
      });
      return response;
    });
};

export const deleteFollowee = (userId, context) => {
  const QUERY = `
mutation unfollowUser($userId: String!) {
  deleteUserFollowUser(input: {id_publisher: $userId, provider: LEGACY}) {
    relatedEntity {
      __typename
      ... on User {
        legacyId
      }
    }
  }
}`;
  return requestGraph({
    query: QUERY,
    variables: { userId: userId }
  })
    .then(response =>
      get(response, ['data', 'deleteUserFollowUser', 'relatedEntity'])
    )
    .then(response => {
      eventEmitter.emit(TrackingEventNames.UNFOLLOW_USER_EVENT, {
        context,
        response
      });
      return response;
    });
};

export const getUserCollection = entityId => {
  const QUERY = /* GraphQL */ `
    query getUserCollection($entityId: String!, $after: String) {
      me {
        user {
          social {
            collections(after: $after) {
              edges {
                node {
                  id
                  name
                  entities(id: [$entityId]) {
                    totalCount
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
        }
      }
    }
  `;

  return exhaustPagination(QUERY, { entityId }, [
    'me',
    'user',
    'social',
    'collections'
  ]).then(simplifyGraphData);
};

export const addEntityToCollection = async (collection, entityId) => {
  const MUTATION = `
    mutation AddEntitiesToCollection($collectionId: String!, $entityIds: [String]!) {
      addUserCollectionEntities(input: {id_collection: $collectionId, id_entities: $entityIds}) {
        id
      }
    }
  `;

  const response = await requestGraph({
    query: MUTATION,
    variables: {
      collectionId: collection.id,
      entityIds: [entityId]
    }
  });

  eventEmitter.emit(TrackingEventNames.ADD_ENTITIES_TO_COLLECTION_EVENT);

  return response;
};

export const addPersonalCollectionWithEntity = async (
  { name, description },
  entityId
) => {
  const MUTATION = `
    mutation AddPersonalCollection($name: String!, $description: String) {
      addUserCollection(input: {name: $name, description: $description}) {
        id
      }
    }
  `;

  const response = await requestGraph({
    query: MUTATION,
    variables: { name, description }
  });

  const collection = get(response, ['data', 'addUserCollection']);

  if (response.errors && response.errors.length) {
    throw new Error(response.errors[0].message);
  }

  const result = await addEntityToCollection(collection, entityId);

  return result;
};
