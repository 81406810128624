import applicationBootstrap from 'website/bootstrap';
import searchTheaterPush from 'website/containers/theaters/search-theater-push';
import initCarouselVideo from 'website/module/advertisement/carousel-video';
import loadAnchorPlus from 'website/module/anchor/anchor-plus';
import initPlayer from 'website/module/player';
import carousel from 'website/module/ui/carousel';
import dropdownLinks from 'website/module/ui/dropdown-custom';
import roller from 'website/module/ui/roller';
import thirdNav from 'website/module/ui/third-nav';

// bootstrap the application / store
applicationBootstrap().then(() => {
  initPlayer();
  thirdNav();

  carousel();

  roller();

  initCarouselVideo();

  dropdownLinks();

  searchTheaterPush();

  loadAnchorPlus();
});
